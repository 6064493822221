import { React } from "react";
import {
	SearchInput,
	SearchContainer,
	SearchButton,
	SearchLink,
	ButtonContainer,
	Logo,
} from "./styles/Search.styled";
import { useNavigate } from "react-router-dom";

export default function Search({ searchTerm, handleChange, getResults }) {
	const navigate = useNavigate();

	const getSearch = (e) => {
		e.preventDefault();
		navigate("/results");
		return getResults(`/search/q=${searchTerm}&num=10`);
	};

	return (
		<SearchContainer onSubmit={getSearch}>
			<Logo>Not Google</Logo>
			<SearchInput
				type="text"
				placeholder="enter search..."
				value={searchTerm}
				onChange={handleChange}
			/>
			<ButtonContainer>
				<SearchButton type="submit">SEARCH</SearchButton>
				{/* <SearchLink
					href="#"
					target="_blank"
				>
					CODE
				</SearchLink> */}
			</ButtonContainer>
		</SearchContainer>
	);
}
